import React from "react";
import { useParams } from 'react-router-dom';
import Product from "../../components/Product";
import Description from "./Description";
import Destinations from "../../components/Destinations";
import Glossaire from "../../components/Glossaire";

// data
import { stays } from "../../mocks/formations";
import { destinations } from "../../mocks/destinations";
import { gallery as galleryData } from "../../mocks/gallery";


const StaysProduct = () => {

  const { id } = useParams();
  const product = stays.find((item) => item.id === id);
  const productGallery = galleryData.find((item) => item.id === id)?.gallery;

  return (
    <>
      <Product
        classSection="section-mb64"
        title={product.title}
        gallery={productGallery || []}
      />
      <Description
        classSection="section"
        title={product.title}
        options={product.options}
        description={product.description}
        descriptions={product.descriptions}
        competences={product.competences}
        price={product.priceActual}
        alternance={product.alternance}
        path={product.pathFile}
      />
      <Destinations items={destinations} />
    </>
  );
};

export default StaysProduct;
