import React, { useState } from "react";
import cn from "classnames";
import styles from "./Description.module.sass";
import Icon from "../../../components/Icon";
import Slider from "react-slick";
import Receipt from "../../../components/Receipt";
import Dropdown from "../../../components/Dropdown";

const items = [
  { title: "15/11/2023", category: "Date", icon: "calendar" },
  { title: "2 participants", category: "Participants", icon: "user" },
];

const navigation = [
  "Prérequis",
  "Programme de formation",
  "Certification",
  "Méthode pédagogique",
  "Modalité d'évaluation"
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const getFirstDayOfCurrentMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1);
};

const formatDate = (date) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('fr-FR', options);
};

const Description = ({ classSection, options, description, descriptions, competences, price, alternance, title, path }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
  };

  const isAlternanceAvailable = alternance === 1;
  const isNotAlternanceAvailable = alternance === 0;
  const [activeIndex, setActiveIndex] = useState(0);

  const tauxParticipation = [
    {
      icon: "marketing",
      title: "100%",
      content: "En 2023, nous avons réalisé un taux d'assiduité de 100%, démontrant une participation totale et constante.",
      color: "#f0f0f0",
    },
    {
      icon: "marketing",
      title: "100%",
      content: "Notre taux de satisfaction a atteint 100% en 2023, reflétant une pleine satisfaction de tous nos clients.",
      color: "#f0f0f0",
    }
  ];

  const tauxParticipationAlternance = [
    {
      title: "N/A",
      content: "Le pourcentage de retour à l'emploi est en cours d'évaluation, ils seront publié courant 2025",
      color: "#f0f0f0",
    },
    {
      title: "N/A",
      content: "Taux de réussite et taux de satisfaction sont en cours d'évaluation, ils seront publié courant 2025",
      color: "#f0f0f0",
    }
  ];

  const firstDayOfMonth = getFirstDayOfCurrentMonth();
  const formattedDate = formatDate(firstDayOfMonth);
  const updatePhrase = isAlternanceAvailable
    ? `La formation a été mise à jour le ${formattedDate}, conformément aux nouvelles conditions NTC`
    : `La formation a été mise à jour le ${formattedDate}`;

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <div className={cn("className", styles.details)}>
            <h4 className={cn("h4", styles.title)}>Détails</h4>
            <div className={cn(styles.options)}>
              {options.map((x, index) => (
                <div className={styles.option} key={index}>
                  <div className={styles.icon} style={{ borderColor: x.color }}>
                    <Icon name={x.icon} size="24" />
                  </div>
                  <div className={styles.text}>{x.title}</div>
                </div>
              ))}
            </div>
            <div className={styles.group}>
              <div className={styles.box}>
                <div className={styles.content}>
                  <h4>Description</h4>
                  {description.split('\n').map((para, index) => (
                    <p key={index}>{para}</p>
                  ))}
                </div>
              </div>
              {isAlternanceAvailable && (
                <div className={styles.box}>
                  <div className={styles.foot}>
                    <div className={styles.nav}>
                      {navigation.map((x, index) => (
                        <button
                          className={cn(styles.link, {
                            [styles.active]: index === activeIndex,
                          })}
                          onClick={() => setActiveIndex(index)}
                          key={index}
                        >
                          {x}
                        </button>
                      ))}
                    </div>
                    <Dropdown
                      className={cn("tablet-show", styles.dropdown)}
                      value={navigation[activeIndex]}
                      setValue={(value) => setActiveIndex(navigation.indexOf(value))}
                      options={navigation}
                    />
                  </div>
                  <div className={styles.content}>
                    {descriptions[activeIndex].split('\n').map((para, index) => (
                      <p key={index}>{para}</p>
                    ))}
                  </div>
                </div>
              )}
              {isNotAlternanceAvailable && (
                <div className={styles.box}>
                  <div className={styles.content}>
                    <h4>Formation inter/intra entreprise</h4>
                    <p>
                      Toute inscription à une session de formation doit se faire selon le calendrier affiché sur le site internet. Une fois cette démarche effectuée, un délai de 14 jours doit être respecté entre la signature de la convention (ou du contrat) et le début de la formation La confirmation de l'action de formation s'effectue par le biais de la signature du document sus - mentionné à l'issue du délai légal de rétractation.
                    </p>
                  </div>
                </div>
              )}
              {isNotAlternanceAvailable && (
                <div className={styles.box}>
                  <div className={cn("h4", styles.title)}>Statistiques</div>
                  <div className={cn("className", styles.services)}>
                    <div className={styles.wrapperSkill}>
                      <Slider className="services-slider" {...settings}>
                        {tauxParticipation.map((x, index) => (
                          <div className={styles.statSlide} key={index}>
                            <div className={styles.statItem} style={{ backgroundColor: x.color }}>
                              <Icon name="check" size="24" />
                              <div className={styles.eventTitle}>{x.title}</div>
                              <div className={styles.eventContent}>{x.content}</div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              )}
              {isAlternanceAvailable && (
                <div className={styles.box}>
                  <div className={cn("h4", styles.title)}>Statistiques</div>
                  <div className={cn("className", styles.services)}>
                    <div className={styles.wrapperSkill}>
                      <Slider className="services-slider" {...settings}>
                        {tauxParticipationAlternance.map((x, index) => (
                          <div className={styles.statSlide} key={index}>
                            <div className={styles.statItem} style={{ backgroundColor: x.color }}>
                              <div className={styles.eventTitle}>{x.title}</div>
                              <div className={styles.eventContent}>{x.content}</div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.box}>
                <div className={cn("h4", styles.title)}>Objectifs</div>
                <div className={cn("className", styles.services)}>
                  <div className={styles.wrapperSkill}>
                    <Slider className="services-slider" {...settings}>
                      {competences.map((x, index) => (
                        <div className={styles.slide} key={index}>
                          <div className={styles.item} style={{ backgroundColor: x.color }}>
                            <div className={styles.titleSkill}>{x.title}</div>
                            <div className={styles.text}>{x.content}</div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
              <div className={styles.box}>
                {updatePhrase}
              </div>
            </div>
          </div>
          
          <Receipt
            className={styles.receipt}
            items={items}
            priceOld=""
            priceActual={price}
            time="Personne"
            alternance={alternance}
            path={path}
          >
          </Receipt>
        </div>
      </div>
    </div>
  );
};

export default Description;