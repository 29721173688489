import React from "react";
import Main from "./Main";
import Destinations from "../../components/Destinations";
import Services from "./Services";
import Faq from "./Faq";
import Categories from "../../components/Categories";
import Newsletter from "../../components/Newsletter";
import Testimonials from "../../components/Testimonials";
import { destinations } from "../../mocks/destinations";
import { categories2 } from "../../mocks/categories";
import Glossaire from "../../components/Glossaire";

const Cars = () => {
  return (
    <>
      <Main />
      <Categories
        classSection="section"
        title="Nos formations"
        info="Découvrez nos programmes de formation"
        items={categories2}
      />
      <Destinations items={destinations} />
      <Services />
      <Glossaire
        classSection="section-mb0"
      />
      <Testimonials classSection="section-pd section-mb0" />
      <Faq />
      <Newsletter />
    </>
  );
};

export default Cars;
