import React from "react";
import cn from "classnames";
import styles from "./Receipt.module.sass";
import Icon from "../../components/Icon";
import { Link } from "react-router-dom";

const Receipt = ({
  className,
  children,
  priceOld,
  priceActual,
  time,
  alternance,
  path
}) => {
  const isAlternanceAvailable = alternance === 1;
  const isNotAlternanceAvailable = alternance === 0;

  const handleExport = () => {
    if (path && path.trim() !== "") {
      const link = document.createElement('a');
      link.href = path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <div className={cn(className, styles.receipt)}>
        <div className={styles.head}>
          <div className={styles.details}>
            {isNotAlternanceAvailable && (
              <div className={styles.cost}>
                <div className={styles.old}>{priceOld}</div>
                <div className={styles.actual}>{priceActual}</div>
                <div className={styles.note}> (Net de taxe) /{time}</div>
              </div>
            )}
            {isAlternanceAvailable && (
              <div className={styles.cost}>
                <p>{priceActual}</p>
              </div>
            )}
          </div>
        </div>
        <div className={styles.btns}>
          {path && path.trim() !== "" && (
            <button className={cn("button-stroke", styles.button)} onClick={handleExport}>
              <span>PDF</span>
            </button>
          )}
          <Link
            className={cn("button", styles.button)}
            to="/contact"
          >
            <span>Contacter</span>
          </Link>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </>
  );
};

export default Receipt;