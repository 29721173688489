import React from "react";
import cn from "classnames";
import styles from "./Best.module.sass";
import Image from "../Image";

const Glossaire = ({ classSection }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = "/ressources/Glossaire.pdf";
    link.download = "Glossaire.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={cn("section", classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.inner}>
          <div className={styles.left}>
            <div className={styles.wrap}>
              <h2 className={cn("h2", styles.title)}>
                Vous êtes perdu ? 
                <span role="img" aria-label="firework">
                  😐
                </span>
              </h2>
              <div className={styles.info}>
                Téléchargez notre glossaire pour mieux comprendre les sigles et le vocabulaire essentiels !
              </div>
              <button className={cn("button button-stroke", styles.button)} onClick={handleDownload}>
                <span>Télécharger le Glossaire</span>
              </button>
            </div>
          </div>
          <div className={styles.right}>
            <Image src="./images/GlossaireIllu.png" alt="Glossaire image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Glossaire;