import React, { useState } from "react";
import cn from "classnames";
import styles from "./Checkout.module.sass";
import Control from "../../components/Control";
import axios from 'axios';
import Dropdown from "../../components/Dropdown";
import TextInput from "../../components/TextInput";
import { Link } from "react-router-dom";
import { stays } from "../../mocks/formations";
import TextAreaInput from "../../components/TextAreaInput";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Checkout = () => {

  const formationTitles = stays.map(formation => formation.title);
  const [formation, setFormation] = useState(formationTitles[0]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://www.wake-up-success.com/send-email', {
        formation, 
        firstName,
        lastName,
        email,
        phone,
        company,
        message
      });
      console.log('Message sent successfully', response.data);
      toast.success('Message envoyé avec succès!');
  
      setFormation('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setCompany('');
      setMessage('');
    } catch (error) {
      console.error('There was an error sending the message', error);
      toast.error('Erreur lors de l\'envoi du message.');
    }
  };



  return (
    <div className={cn("section-mb80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <Control
          className={styles.control}
          urlHome="/"
        />
        <ToastContainer />
        <div className={styles.wrapper}>
          <div>
            <div className={cn("h2", styles.title)}>Contactez nous</div>
            <div className={cn(styles.section)}>
              <div className={cn(styles.wrapper)}>
                <div className={cn(styles.formWrapper)}>
                  <form>
                    <Dropdown
                      label="Prénom"
                      className={styles.dropdown}
                      value={formation}
                      setValue={setFormation}
                      options={formationTitles}
                    />
                    <TextInput
                      className={styles.field}
                      label="Prénom"
                      name="firstName"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="Nom"
                      name="lastName"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="Email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="Téléphone"
                      name="phone"
                      type="tel"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="Entreprise"
                      name="company"
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      required
                    />
                    <TextAreaInput
                      label="Entreprise"
                      name="message"
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                  </form>
                  <div className={styles.control}>
                    <Link to="/" className={cn("button", styles.button)} onClick={handleSubmit}>
                      Envoyer
                    </Link>
                  </div>
                </div>
                <div className={cn(styles.content)}>
                  <h1>Informations :</h1>
                  <p>
                    Remplissez le formulaire et notre équipe vous répondre dans les 24h.
                  </p>
                  <ul>
                    <li>(+33) 7 77 84 23 43</li>
                    <li>contact@wake-up-success.com</li>
                    <li>50 Avenue du Peuple Belge 59000, Lille</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

