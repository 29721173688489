import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import styles from "./Catalog.module.sass";
import Card from "../../../components/Card";

// data
import { stays, stays1, stays2 } from "../../../mocks/formations";
const allStays = [stays, stays1, ...stays2.map(group => group.items)].flat();
const INITIAL_LOAD_COUNT = 9;
const LOAD_MORE_COUNT = 3;

const Catalog = ({ searchQuery }) => {
  const [filteredStays, setFilteredStays] = useState(allStays.slice(0, INITIAL_LOAD_COUNT));
  const [loadIndex, setLoadIndex] = useState(INITIAL_LOAD_COUNT);
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCategory = queryParams.get('category');

  useEffect(() => {
    let filteredData;
    if (searchQuery) {
      filteredData = allStays.filter(stay => stay.title.toLowerCase().includes(searchQuery.toLowerCase()));
    } else if (selectedCategory) {
      filteredData = allStays.filter(stay => stay.category && stay.category.toLowerCase() === selectedCategory.toLowerCase());
    } else {
      filteredData = allStays.slice(0, INITIAL_LOAD_COUNT);
    }
  
    setFilteredStays(filteredData);
    console.log("Filtered Data:", filteredData);
  }, [searchQuery, selectedCategory]);
  
  
  const handleLoadMore = () => {
    const nextLoadLimit = loadIndex + LOAD_MORE_COUNT;
    const nextStays = searchQuery
      ? filteredStays.slice(loadIndex, nextLoadLimit)
      : allStays.slice(loadIndex, nextLoadLimit);

    setFilteredStays(prevFilteredStays => [...prevFilteredStays, ...nextStays]);
    setLoadIndex(nextLoadLimit);
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <h4 className={cn("h4", styles.title)}>Plus de 100 formations</h4>
          <div className={styles.list}>
            {filteredStays.length > 0 ? (
              filteredStays.map((x, index) => (
                <Card className={styles.card} item={x} alternance={x.alternance} key={index} />
              ))
            ) : (
              <div className={styles.info}>
                Aucun résultat n'a été trouvée.
              </div>
            )}
          </div>
          {loadIndex < allStays.length && ( // Vérifie s'il reste des éléments à charger
            <div className={styles.btns}>
              <button className={cn("button-stroke", styles.button)} onClick={handleLoadMore}>
                Voir plus
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Catalog;
